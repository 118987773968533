@import "../variables";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
/* stylelint-disable property-no-unknown */

.hero-banner-wrapper {
    overflow: hidden;
    background-color: $grey2;
    aspect-ratio: 18 / 7;
    transition: background-color 0.5s 0.5s;
    color: $white;
    position: relative;

    img.lazy {
        opacity: 0;
        object-fit: cover;
        object-position: 50% 50%;
        transition: opacity 0.5s ease 0.3s;
        will-change: opacity;
        width: 100%;
        height: 100%;

        &.loaded {
            opacity: 1;
        }
    }

    figcaption {
        max-width: 540px;
        padding: 20px;
        z-index: 1;

        h2,
        h3 {
            font-size: $heading-2-font-size;
            line-height: 53px;
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        p {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 32px;
        }

        .cta-container {
            gap: 20px;
        }
    }

    .banner-fill,
    .banner-fill img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    .banner-fill {
        &.image,
        &.video {
            background-color: $grey2;
        }
    }

    .banner-fill.video {
        iframe,
        .placeholder {
            background-color: unset;
        }

        iframe {
            position: absolute;
            left: 0;
            top: 0;
            // Workaround for Vimeo's poor responsive rounding
            // which causes the background to leak as a border
            // at various screen widths
            // JS sets the width to 100% on play which forces
            // the vimeo player to fix being off by .02px
            width: 99%;
            height: 100%;
        }
    }

    .banner-fill.mobile {
        display: none;
    }

    .banner-fill.desktop {
        display: block;
    }

    @include media-breakpoint-down(md) {
        aspect-ratio: unset;

        figcaption {
            max-width: unset;

            h2,
            h3 {
                font-size: $heading-3-font-size;
                line-height: 30px;
            }

            h2,
            h3,
            p {
                line-height: 20px;
                margin-bottom: 20px;
            }

            p {
                font-size: 14px;
                line-height: 20px;
            }

            .cta-container {
                gap: 10px;
            }
        }

        .banner-fill {
            &.image {
                aspect-ratio: 1.5;
            }

            &.video {
                aspect-ratio: 9 / 7;
            }
        }

        .banner-fill,
        .banner-fill img {
            position: relative;
        }

        .banner-fill.placeholder,
        .banner-fill.placeholder img {
            position: absolute;
        }

        .banner-fill.mobile {
            width: 100%;
            height: 100%;
            display: block;
        }

        .banner-fill.desktop {
            display: none;
        }
    }
}
